<template>
	<div v-if="isSuperAdmin">
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
	<div v-else>
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
		></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */

import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import StatusActions from './PmStatusActions';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '../../api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmstatusgrid',
	data() {
		return {
			model: 'pmstatus',
			actions: StatusActions.actions,
			masterDetailColumnVisibles: {
				parametername: true,
				organizationname: true,
				name: true,
				color: true,
				minvalue: true,
				maxvalue: true,
				description: true,
				pmdatasourcetypename: true,

				disabled: true
			},
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				/* parametername: {
					createdCell: (td, cellData, rowData) => {
						return (td.innerHTML = `<label style="font-weight:bold;">${cellData} - ${rowData.pmparameterid}</label>`);
					}
				}, */
				name: {
					createdCell: (td, cellData) => {
						if (cellData) {
							let innerCell = `<label>${cellData}</label>`;
							if (cellData.charAt(0) == '[') {
								const names = JSON.parse(cellData);
								innerCell = '<div style="display:flex;">';
								names.forEach((name, i) => {
									if (i == names.length - 1) innerCell += '<span style="padding-right:4px;">' + name + '</span>';
									else innerCell += '<span style="padding-right:4px;">' + name + ', </span>';
								});
								innerCell += '</div>';
							}

							return (td.innerHTML = innerCell);
						}
						return;
					}
				},
				color: {
					createdCell: (td, cellData) => {
						if (cellData) {
							let innerCell = `<label style="font-weight:bold;color:${cellData}">${cellData}</label>`;
							if (cellData.charAt(0) == '[') {
								const colors = JSON.parse(cellData);
								innerCell = '<div style="display:flex;">';
								colors.forEach((color, i) => {
									if (i == colors.length - 1)
										innerCell += '<span style="color:' + color + ';font-weight:bold;padding-right:4px;">' + color + ' </span>';
									else innerCell += '<span style="color:' + color + ';font-weight:bold;padding-right:4px;">' + color + ', </span>';
								});
								innerCell += '</div>';
							}

							return (td.innerHTML = innerCell);
						}
						return;
					}
				}
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'and',
				rules: [
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
					/* { field: 'langstatus', op: 'eq', data: 1 } */
				],
				groups: []
			};
			console.log('external filter', this.externalFilter);
		}
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
