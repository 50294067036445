import { disableStatuses, enableStatuses } from '@/api/pmstatus';

const disableStatus = {
	id: 'disableStatus',
	selectionType: 'multiple',
	label: 'pmstatus.actions.disableStatus',
	functionality: 'UPDATE_PMSTATUS',
	checkAvailability: function (status) {
		return status && status.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmstatus.actions.disableStatus');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmstatusid: registries[i].pmstatusid
			});
		}

		const data = await disableStatuses(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableStatus = {
	id: 'enableStatus',
	selectionType: 'multiple',
	label: 'pmstatus.actions.enableStatus',
	functionality: 'UPDATE_PMSTATUS',
	checkAvailability: function (status) {
		return status && status.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmstatus.actions.enableStatus');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmstatusid: registries[i].pmstatusid
			});
		}

		const data = await enableStatuses(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableStatus, enableStatus]
};
